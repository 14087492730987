import { getApiUrl } from "../utils/getApiUrl";
import { fetchWithUserId } from "../utils/apiCalls";
import logger from "../utils/logger";

export const checkTemplate = async (title, html) => {
    try {
        const urlParams = new URLSearchParams();
        urlParams.append('title', title);
        urlParams.append('html', html);
        const result = await fetchWithUserId(`${getApiUrl()}/check_template?${urlParams.toString()}`);

        if (!result.ok) {
            // Handle non-successful HTTP responses
            throw new Error(`HTTP error! Status: ${result.status}`);
        }

        // Parse the JSON response
        return await result.json();
    } catch (error) {
        // Handle network errors, parsing errors, or any other unexpected issues
        logger.log('error', `An error occurred during the API call check_template: ${error}`);
        return {status: 'fail', details: `Server returned error: ${error.message}`};
    }
};
