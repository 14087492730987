import pMemoize from 'p-memoize';
import logger from '../utils/logger';
import {isValidEmail} from "./isValidEmail";
import {getApiUrl} from "../utils/getApiUrl";
import {axiosRequestWithUserId} from "../utils/apiCalls";

const getEmailContent = pMemoize(
    async (email, template) => {
        if (!isValidEmail(email) || !template) {
            return undefined; // Return early if validation fails
        }

        const api = 'email_content';
        const url = `${getApiUrl()}/${api}?email=${email}&template=${template}`;
        const config = {
            method: 'get',
            url,
            maxBodyLength: Infinity,
        };

        try {
            const { data } = await axiosRequestWithUserId(config);
            return data;
        } catch (error) {
            logger.error(error.toString());
        }
    },
    {
        cacheKey: (email, template) => `${email}:${template}`,
        maxAge: 30 * 60 * 1000, // 30 min
    }
);

export { getEmailContent };
