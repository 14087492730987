import { emailInSpamDb as checkEmailInSpam } from "../../services/emailInSpamDb";

export function verifyLeads(campaigns, campaignIndex, assignAttachmentName, templates, findTemplate) {
    const toRowNumber = (index) => index + 1; // Helper function for 1-indexed row numbers

    const validators = [
        {
            condition: (lead) => lead.name && lead.company && lead.email && lead.role,
            message: (index) => `Some column value for a lead at row ${toRowNumber(index)} is empty`,
        },
        {
            condition: (lead) => !lead.company.includes('&'),
            message: (index, lead) => `Company ${lead.company} includes '&' at row ${toRowNumber(index)}`,
        },
        {
            condition: (lead) => /^[A-Z]/.test(lead.name),
            message: (index, lead) => `Name ${lead.name} does not start with a capital letter at row ${toRowNumber(index)}`,
        },
        {
            condition: (lead) => /^[A-Za-z\s]*$/.test(lead.name),
            message: (index, lead) => `Name ${lead.name} contains non-English letters at row ${toRowNumber(index)}`,
        },
        {
            condition: (lead) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(lead.email),
            message: (index, lead) => `Email ${lead.email} contains unsupported characters at row ${toRowNumber(index)}`,
        },
        {
            condition: (lead) => !/\.edu$|\.gov$/i.test(lead.email),
            message: (index, lead) => `Email ${lead.email} has a restricted domain (.edu or .gov) at row ${toRowNumber(index)}`,
        },
    ];

    async function areLeadsValid() {
        const result = { status: "FAILED", message: "" };
        const leads = campaigns[campaignIndex].leads;

        if (leads.length === 0) {
            result.message = "No leads to outreach";
            return result;
        }

        for (const [index, lead] of leads.entries()) {
            for (const { condition, message } of validators) {
                if (!condition(lead)) {
                    result.message = message(index, lead); // Single point of 1-indexed row reference
                    return result;
                }
            }
        }

        const spamCheckResults = await Promise.all(
            leads.map(async (lead) => ({
                email: lead.email,
                inSpamDb: await checkEmailInSpam(lead.email),
            }))
        );

        const spamEmail = spamCheckResults.find(({ inSpamDb }, index) => {
            if (inSpamDb) {
                result.message = `Email ${leads[index].email} is in the spam database at row ${toRowNumber(index)}`; // Single reference
                return true;
            }
            return false;
        });

        if (spamEmail) {
            return result;
        }

        result.status = "OK";
        return result;
    }

    return areLeadsValid;
}