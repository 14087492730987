import React from "react";
import "./PugConverter.css";
import TemplateTabs from "./components/TemplateTabs";
import Button from "../../components/Button/Button";
import NewTemplateModal from "./components/NewTemplateModal";
import EditorSection from "./components/EditorSection";
import useEmailTemplate from "./useEmailTemplate";
import EmailSectionVisualize from "./components/EmailSectionVisualize";

export default function PugConverter() {
    const {
        activeTab,
        closeNewTemplateModal,
        html,
        newModalTemplateVisible,
        openNewTemplateModal,
        setActiveTab,
        setTabs,
        tabs,
        createNewTemplate,
        isEditAvailable,
        handleChangeTabTitle,
        handleSaveTemplateStorage,
        setCode,
        notEditableTabsIds,
        handleSendEmail,
        code,
        loading,
    } = useEmailTemplate();

     const handleEmailSelf = () => {
        const confirmed = window.confirm("Are you sure you want to send an email to yourself?");
        if (confirmed) {
            handleSendEmail();
        }
    };
    
    return (
        <>
            <div className="pug-converter-container">
                <NewTemplateModal
                    createNewTemplate={createNewTemplate}
                    isOpen={newModalTemplateVisible}
                    onRequestClose={closeNewTemplateModal} />
                <div className="input-group">
                    <Button onClick={openNewTemplateModal}>New</Button>
                    <Button onClick={handleSaveTemplateStorage} disabled={loading}>
                        {loading ? "Saving..." : "Save"}
                    </Button>
                    <Button
                        style={{
                            width: 'fit-content',
                        }}
                         onClick={handleEmailSelf}>Email (self)</Button>
                </div>
                <TemplateTabs
                    setCode={setCode}
                    handleChangeTabTitle={handleChangeTabTitle}
                    tabs={tabs}
                    setTabs={setTabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    isEditAvailable={isEditAvailable}
                    notEditableTabsIds={notEditableTabsIds}
                />

                {/* Show a global spinner while loading */}
                {loading && (
                    <div className="spinner-container">
                        <div className="spinner"></div>
                    </div>
                )}
            </div>
            <div>
                <EditorSection
                    editorValue={code}
                    isEditAvailable={isEditAvailable}
                    setCode={setCode}
                />
                <EmailSectionVisualize html={html} />
            </div>
        </>
    );
}